@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css');


* {
  font-family: 'Pretendard';
}

.columnContents {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.defaultText {
  font-size: 15px;
  font-weight: 800;
  line-height: 24px;
  letter-spacing: -0.5px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes modal-bg-show {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

.modalBase {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 99;
  background-color: rgba(0, 0, 0, 0.6);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 8px 36px rgba(0, 0, 0, 0.16);
  animation: modal-bg-show .3s;
}

@keyframes showModal {
  from {bottom: 0; opacity: 0; transform: translateY(30px);}
  to {bottom: 30px; opacity: 1; transform: translateY(0);}
}


.modalPaper {
  display: flex;
  flex-direction: column;
  
  width: 700px;
  border-radius: 8px;
  background: #E9ECEF;
  animation: showModal 0.5s;
}

.tableRow {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  background: white;
}

.tableCell {
  border-width: 0px 1px 1px 0px;
  border-style: solid;
  border-color: #A2A2A2;
  font-size: 11px;
  height: 25px;
  text-align: left;
  padding-left: 5px;
  line-height: 25px;
  font-weight: 400;
}

.cellTextInput {
  width: 100px;
  height: 20px;
  outline: none;
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
  border: none;
  background: none;
}

.cellSelect {
  width: 100px;
  height: 20px;
  outline: none;
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
  border: none;
  background: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.tabButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  color: #3A57E8;
  cursor: pointer;
}

.tabButtonSelected {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  color: white;
  cursor: pointer;
  background: #3A57E8;
  border-radius: 50px;
}

.graphButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  font-size: 13px;
  color: gray;
  cursor: pointer;
}

.graphButtonSelected {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  color: black;
  cursor: pointer;
  font-size: 13px;
  background-color: #ebebeb;
  box-shadow: 0px 1px 6px 0.1px gray;
  border-radius: 40px;
}

.pageButton {
  width: 24px;
  height: 24px;
  cursor: pointer;
  text-align: center;
  line-height: 24px;
  font-size: 14px;
  color: #54595E;
  font-weight: 500;
}

.pageButtonSelected {
  width: 24px;
  height: 24px;
  cursor: pointer;
  text-align: center;
  line-height: 24px;
  font-size: 14px;
  color: #54595E;
  font-weight: 500;
  border: 1px solid rgba(84, 89, 94, 0.7);
  border-radius: 5px; 
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}

.surveyRow1 {
  width: 100%;
  display: grid;
  padding: 4px 0px;
  grid-template-columns: 0.05fr 2.1fr 2fr;
  line-height: 20px;
  background: #F6F6F6;
  border-radius: 4px;
  font-size: 14px;
}

.surveyRow2 {
  width: 100%;
  display: grid;
  padding-top: 2px;
  grid-template-columns: 0.05fr 2.1fr 2fr;
  line-height: 20px;
  border-radius: 4px;
  background: white;
  font-size: 14px;
}

.answerRowGroup {
  display: flex;
  gap: 30px;
  color: '#54595E'
}

.answerColumnGroup {
  display: flex;
  flex-direction: column;
  gap: 4px;
  color: '#54595E'
}

.answerItem {
  display: flex;
  gap: 5px
}

.comboIcon {
  width: 22px;
  height: 22px;
  margin-top: 0px;
}

.answerHighlightedText {
  font-weight: 700;
  color: #3A57E8;
}

.dashboardTableTop {
  width: 99%;
  display: grid;
  grid-template-columns: 1.5fr 6fr 1fr 1fr 1fr;
  gap: 10px;
  line-height: 20px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  color: #54595E;
  padding: 2px 4px;
}

.dashboardTableRow1 {
  width: 99%;
  display: grid;
  grid-template-columns: 1.5fr 6fr 1fr 1fr 1fr;
  gap: 10px;

  line-height: 20px;
  background: #F6F6F6;
  border-radius: 4px;
  font-weight: 600;
  color: #54595E;
  font-size: 13px;
  padding: 2px 4px;
}

.dashboardTableRow2 {
  width: 99%;
  display: grid;
  gap: 10px;

  grid-template-columns: 1.5fr 6fr 1fr 1fr 1fr;
  line-height: 20px;
  border-radius: 4px;
  font-weight: 600;
  color: #54595E;
  font-size: 13px;
  padding: 2px 4px;
}